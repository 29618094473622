import React, { ReactElement, useEffect, useState } from 'react';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import ContentfulModule from '@Src/components/ContentfulModule';
import SEO from '@Components/SEO';
import '@GraphQL/fragments';
import Signup from '@Src/components/Signup';
import LinkForm from '@Src/components/Signup/Form/link';
import PitchForm from "@Components/Pitch/Form";
import Text from '@Src/components/Text';
import { SubmitButton } from '@Src/components/ui/SubmitButton';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
}

const PitchPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = '', seoSettings = 'label pitch', content = ''
    const { setTheme: setPageTheme } = usePageTheme();
    const { mode, pageType, setPageType, setPageTitle } = useApp();
    const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

    useEffect(() => {
        if (pageType === 'index' && transitionStatus == 'exiting') {
            setLocalTransStatus(transitionStatus + 'Index');
        } else {
            setLocalTransStatus(transitionStatus);
        }

        if (transitionStatus == 'entering' || transitionStatus == 'entered') {
            setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'night');
            setPageTitle(title);
            setPageType(pageContext.type);
        }
    }, [transitionStatus]);

    useEffect(() => {
        if (transitionStatus == 'entered') {
            setPageTheme(mode == 'day' ? 'yellow' : 'dark');
        }
    }, [mode]);

    return (
        <ContentLayout transitionState={localTransStatus} fullWidth type={'standard'}>
            <SEO
                location={location.pathname}
                title={seoSettings?.title || title}
                description={seoSettings?.description}
                meta={seoSettings?.metaTags}
                image={seoSettings?.image}
            />
            {content &&
                content.map(
                    (module: IBlock, index: number): ReactElement => (
                        <ContentfulModule key={module.id} type={module.type} data={module} />
                    )
                )}
            <Text theme={mode} heading01={"Label Pitch"} />
            <Text theme={mode} htmlText={`Please submit your pitches well in advance. We appreciate hearing about your priorities here 6-8 weeks in advance for each new release. Click the button below to enter the form`} />
            <SubmitButton
              onClick={() => { window.open(`https://bps31rmjlv4.typeform.com/labelpitch?utm_source=aloaded.com&utm_campaign=Temporary+redirect`) }}
              theme={mode}
              label={`Click here to enter the form`} 
            />
            <div style={{color: 'white'}}>
              <p>Company details:</p>
              <p>ALOADED AB<br />
              Bondegatan 21<br />
              116 33 Stockholm<br />
              Sweden<br />
              <br />Company Approved for Company Tax (Godkänd för F-skatt) <br />
              with national company registration number: 559124-3836<br />
              VAT SE559124383601
              </p>
            </div>
        </ContentLayout>
    );
};

export default PitchPage;